/* eslint jsx-a11y/label-has-for:0 */

import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Layout, Wrapper, Header } from '../components'

import config from '../../config'

const Content = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  margin-top: -3rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  form {
    p {
      label,
      input {
        display: block;
      }
      input {
        min-width: 275px;
        margin-top: 0.5rem;
      }
      textarea {
        resize: vertical;
        min-height: 150px;
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`

const Reading = () => (
  <Layout>
    <Wrapper>
      <Helmet title={`Reading | ${config.siteTitle}`} />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
      </Header>
      <Content>
        <h1>Sheree Reads</h1>
        <ul>
          <li>
            <a href="https://97things.incidentlabs.io">97 Things Every SRE Should Know</a> Quick read. Lots of people
            you follow on Twitter saying smart stuff.
          </li>
          <li>
            <a href="https://www.amazon.com/Age-Surveillance-Capitalism-Future-Frontier/dp/1610395697">
              The Age of Surveillance Capitalism: The Fight for a Human Future at the New Frontier of Power
            </a>{' '}
            It's a thick and worthwhile read, even if it is terrifying.
          </li>

          <li>
            <a href="https://www.amazon.com/Making-Work-Visible-Exposing-Optimize/dp/B07776XY3D">
              Making Work Visible: Exposing Time Theft to Optimize Work & flow
            </a>{' '}
            My love for kanban and making glue work visible knows no bounds.
          </li>
        </ul>
      </Content>
    </Wrapper>
  </Layout>
)

export default Reading
